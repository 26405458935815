import {
  Breadcrumbs,
  Card,
  Link as MuiLink,
  Stack,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";

import { CircularLoading } from "@/components/CircularLoading";
import { getDocumentTitle, useRequireParams } from "@/utils";
import { getFacility } from "@/services/facility";

import { FacilityDetail } from "./FacilityDetail";
import { FacilityBookingList } from "./FacilityBookingList";

const QUERY_KEY = "getFacility";

export function FacilityDetailPage() {
  const { id } = useRequireParams(["id"]);

  const { data, isLoading } = useQuery(QUERY_KEY, () => getFacility(id));

  const title = "สิ่งอำนวยความสะดวก";

  return (
    <Stack>
      <Helmet>
        <title>{getDocumentTitle(title)}</title>
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <MuiLink component={Link} color="text.secondary" to="/facilities">
          {title}
        </MuiLink>
        <Typography color="text.primary">{data?.name ?? ""}</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 3 }}>
        {isLoading ? (
          <CircularLoading height={436} />
        ) : (
          data && <FacilityDetail data={data} fetchKeys={[QUERY_KEY]} />
        )}
      </Card>
      <FacilityBookingList />
    </Stack>
  );
}
