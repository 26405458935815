import {
  Box,
  Card,
  CardContent,
  Stack,
  Button,
  TableContainer,
  Table,
  TableHead,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";

import { QueryTextField } from "@/components/QueryTextField";
import { DataTableBody } from "@/components/DataTableBody";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { ItemsTotal } from "@/components/ItemsTotal";
import { PurchaseProductEditorDialog } from "@/features/product/components/PurchaseProductEditorDialog";
import { getDocumentTitle, pickListTableParams } from "@/utils";
import { getProducts } from "@/services/product";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";

import { ProductTableRow, ProductTableRowHeader } from "./ProductTableRow";
import { RemoveProductDialog } from "../../components/RemoveProductDialog";
import { AddProductDialog } from "./AddProductDialog";
import { EditProductDialog } from "../../components/EditProductDialog";

const QUERY_KEY = "products";

export function ProductListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentUser = useCurrentUser();
  const query = pickListTableParams(searchParams);

  const { data: raw, isLoading } = useQuery([QUERY_KEY, query], () =>
    getProducts(query)
  );

  const data = raw?.data ?? [];
  const total = raw?.total;

  function add() {
    searchParams.set("dialog", "product-add");
    setSearchParams(searchParams);
  }

  function purchase() {
    searchParams.set("dialog", "product-purchase");
    setSearchParams(searchParams);
  }

  const dialog = searchParams.get("dialog");
  const id = searchParams.get("id") ?? "";

  function onCloseDialog() {
    searchParams.delete("dialog");
    searchParams.delete("id");
    setSearchParams(searchParams, { replace: true });
  }

  const addDialog = {
    open: dialog === "product-add",
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  const editDialog = {
    open: dialog === "product-edit",
    onClose: onCloseDialog,
    id,
    data: data.find((item) => item.id === +id),
    fetchKeys: [QUERY_KEY],
  };

  const removeDialog = {
    open: dialog === "product-remove",
    id,
    onClose: onCloseDialog,
  };

  const purchaseDialog = {
    open: dialog === "product-purchase",
    onClose: onCloseDialog,
  };

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle("สินค้า")}</title>
      </Helmet>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={4}
            mb={5}
          >
            <QueryTextField sx={{ width: 600 }} placeholder="ค้นหาชื่อ" />
            <Stack direction="row" gap={2}>
              <Button
                color="inherit"
                variant="contained"
                size="large"
                onClick={purchase}
              >
                ซื้อสินค้า
              </Button>
              {currentUser.isAdmin && (
                <Button variant="contained" size="large" onClick={add}>
                  เพิ่มสินค้า
                </Button>
              )}
            </Stack>
          </Stack>
          <ItemsTotal count={total} />
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <ProductTableRowHeader />
              </TableHead>
              <DataTableBody loading={isLoading} data={data}>
                {data.map((item) => (
                  <ProductTableRow key={item.id} data={item} />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
      <AddProductDialog {...addDialog} />
      <EditProductDialog {...editDialog} />
      <RemoveProductDialog {...removeDialog} />
      <PurchaseProductEditorDialog {...purchaseDialog} />
    </Box>
  );
}
