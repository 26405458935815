import {
  Box,
  Card,
  CardContent,
  Stack,
  Button,
  TableContainer,
  Table,
  TableHead,
  ButtonGroup,
  Breadcrumbs,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useMutation, useQuery } from "react-query";
import { useAtom } from "jotai";
import { useState } from "react";

import { QueryTextField } from "@/components/QueryTextField";
import { DataTableBody } from "@/components/DataTableBody";
import { ItemsTotal } from "@/components/ItemsTotal";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { getDocumentTitle, mapOptional, pickListTableParams } from "@/utils";
import { checkCreate, getMembers } from "@/services/member";
import { MemberStatus } from "@/models";
import { formatMemberStatus } from "@/formatter";
import { selectedBranchAtom } from "@/atom/global";
import { AxiosErrorWithData } from "@/client/api";

import { BanMemberDialog } from "../../components/BanMemberDialog";
import { MemberVerifyDialog } from "./MemberVerifyDialog";
import { MemberTableRow, MemberTableRowHeader } from "./MemberTableRow";
import { AddMemberDialog } from "./AddMemberDialog";
import { MemberAlertDialog } from "../../components/MemberAlertDialog";

const QUERY_KEY = "members";

export function MemberListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = pickListTableParams(searchParams);
  const [selectedBranch] = useAtom(selectedBranchAtom);
  const { data: raw, isLoading } = useQuery(
    [QUERY_KEY, query, selectedBranch],
    () => getMembers(query)
  );
  const { mutate: checker } = useMutation(checkCreate, {
    onSuccess: () => {
      searchParams.set("dialog", "member-add");
      setSearchParams(searchParams);
    },
    onError: (error: AxiosErrorWithData) => {
      setIsAlert(true);
      setCount(0);
    },
  });

  const [isAlert, setIsAlert] = useState(false);
  const [count, setCount] = useState<number>(0);

  const status = searchParams.get("status") as MemberStatus | undefined;
  const setStatus = (s: MemberStatus | null) => () => {
    if (s) {
      searchParams.set("status", s);
    } else {
      searchParams.delete("status");
    }

    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const data = raw?.data ?? [];
  const total = raw?.total;

  const dialog = searchParams.get("dialog");
  const id = searchParams.get("id") ?? "";

  function onCloseDialog(status = "cancel") {
    if (status === "success") {
      searchParams.delete("page");
      searchParams.delete("status");
      searchParams.delete("query");
      searchParams.delete("sort");
      searchParams.delete("sortType");
    }
    searchParams.delete("dialog");
    searchParams.delete("id");
    searchParams.delete("member-id");
    setSearchParams(searchParams, { replace: true });
  }

  function add() {
    checker();
  }

  const addDialog = {
    open: dialog === "member-add",
    onClose: onCloseDialog,
  };

  const member = data.find((member) => member.id === +id);
  const verifyDialog = {
    title: "ยืนยันสมาชิก",
    open: typeof member !== "undefined" && dialog === "member-verify",
    onClose: onCloseDialog,
    data: member,
  };

  const banDialog = {
    open: typeof member !== "undefined" && dialog === "member-ban",
    onClose: onCloseDialog,
    data: member,
    fetchKey: QUERY_KEY,
  };

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle("Member")}</title>
      </Helmet>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Typography color="text.primary">สมาชิก</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
              mb={3}
            >
              <QueryTextField
                sx={{ width: 400 }}
                placeholder="ค้นหาด้วยเลขสมาชิก ชื่อ อีเมล หรือ เบอร์โทรศัพท์"
              />
              <ButtonGroup variant="contained" size="large" color="inherit">
                {[
                  null,
                  MemberStatus.Active,
                  MemberStatus.Expired,
                  MemberStatus.Ban,
                ].map((s) => (
                  <Button
                    key={s}
                    onClick={setStatus(s)}
                    sx={{ bgcolor: s === status ? "grey.100" : "grey.300" }}
                  >
                    {mapOptional(s, formatMemberStatus) ?? "ทั้งหมด"}
                  </Button>
                ))}
              </ButtonGroup>
              <Button variant="contained" size="large" onClick={add}>
                เพิ่มสมาชิก
              </Button>
            </Stack>
            <ItemsTotal count={total} />
            <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
              <Table stickyHeader>
                <TableHead>
                  <MemberTableRowHeader />
                </TableHead>
                <DataTableBody loading={isLoading} data={data}>
                  {data.map((item) => (
                    <MemberTableRow key={item.id} data={item} />
                  ))}
                </DataTableBody>
              </Table>
            </TableContainer>
          </Stack>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
      <AddMemberDialog {...addDialog} />
      <BanMemberDialog {...banDialog} />
      <MemberVerifyDialog {...verifyDialog} />
      <MemberAlertDialog
        open={isAlert}
        onClose={() => void setIsAlert(false)}
        count={count}
      />
    </Box>
  );
}
