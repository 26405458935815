import {
  Box,
  Breadcrumbs,
  Typography,
  Card,
  CircularProgress,
  Link as MuiLink,
  Tabs,
  TabsProps,
  Tab,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { useQuery } from "react-query";
import NavigateNext from "@mui/icons-material/NavigateNext";

import { getDocumentTitle, useRequireParams } from "@/utils";
import { getTrainer } from "@/services/trainer";

import { TrainerProfile } from "./TrainerProfile";

import type { Profile } from "@/models";

function useTrainer(id: string) {
  return useQuery("trainer", () => getTrainer(id));
}

export function TrainerProfilePage() {
  const { id } = useRequireParams(["id"]);

  const location = useLocation();
  const navigate = useNavigate();

  const { data, isLoading } = useTrainer(id);

  const path = location?.pathname ?? "";

  const isLoaded = !isLoading && typeof data !== "undefined";

  const title = "Trainer Profile";

  const isPersonalTraining =
    data?.trainerProfileData?.personalTraining ?? false;

  const onChangeTab: TabsProps["onChange"] = (_, value: string) => {
    navigate(value, { replace: true });
  };

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle(title)}</title>
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <MuiLink component={Link} color="text.secondary" to="..">
          เทรนเนอร์
        </MuiLink>
        <MuiLink component={Link} color="text.secondary" to="..">
          รายชื่อเทรนเนอร์
        </MuiLink>
        <Typography color="text.primary">ข้อมูลเทรนเนอร์</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 2.5 }}>
        {isLoaded ? (
          <TrainerProfile data={data} />
        ) : (
          <Box display="grid" sx={{ placeItems: "center" }} height={1000}>
            <CircularProgress disableShrink />
          </Box>
        )}
      </Card>

      <Tabs value={path} onChange={onChangeTab} sx={{ mb: 2 }}>
        <Tab label="ตารางเวลา" value={`/trainers/${id}`} />
        <Tab
          label="ประวัติการสอนคลาส"
          value={`/trainers/${id}/class-history`}
        />
        <Tab
          label="ประวัติการเทรนส่วนตัว"
          value={`/trainers/${id}/pt-history`}
        />
        <Tab
          label="การตั้งเป้าหมายการเทรน"
          value={`/trainers/${id}/trainings`}
        />
      </Tabs>
      <Outlet context={{ isPersonalTraining, profile: data?.profile }} />
    </Box>
  );
}

type ContextType = { isPersonalTraining: boolean; profile: Profile };

export function useTrainerProfilePage() {
  return useOutletContext<ContextType>();
}
