import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import NavigateNext from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  Card,
  CircularProgress,
  Link as MuiLink,
  Stack,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";

import { getDocumentTitle, useRequireParams } from "@/utils";

import { StaffProfile } from "./StaffProfile";
import { TrainerProfile } from "./TrainerProfile";
import { getStaff } from "@/services/staff";
import { StaffPermissionGroup } from "./StaffPermissionGroup";

export function useStaff(id: string, queryKey: string) {
  return useQuery(queryKey, () => getStaff(id));
}

export function StaffProfilePage() {
  const { id } = useRequireParams(["id"]);

  const quearyKey = `staff_${id}`;

  const { data: profileData, isLoading } = useStaff(id, quearyKey);

  const isLoaded = !isLoading && typeof profileData !== "undefined";
  const title = "Staff Profile";
  const isShowPermission =
    !!profileData?.fitnessCenter?.hasPermissionGroupControl;

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle(title)}</title>
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <MuiLink component={Link} color="text.secondary" to="..">
          พนักงาน
        </MuiLink>
        <Typography color="text.primary">ข้อมูลพนักงาน</Typography>
      </Breadcrumbs>
      <Stack spacing={3}>
        {isLoaded ? (
          <>
            <StaffProfile data={profileData} />
            {profileData?.trainerProfileData &&
              !profileData?.trainerProfileData?.deletedAt && (
                <TrainerProfile data={profileData} />
              )}
            {isShowPermission && (
              <StaffPermissionGroup
                data={profileData}
                fetchKeys={[quearyKey]}
              />
            )}
          </>
        ) : (
          <Card>
            <Box display="grid" sx={{ placeItems: "center" }} height={1192}>
              <CircularProgress disableShrink />
            </Box>
          </Card>
        )}
      </Stack>
    </Box>
  );
}
