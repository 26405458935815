import { useState } from "react";
import { Typography, Box } from "@mui/material";
import UploadFile from "@mui/icons-material/UploadFile";
import { selectedImageFilenameAtom, selectedImageSrcAtom } from "@/atom/global";
import { useAtom } from "jotai";
import { useSnackbar } from "notistack";

const MAXIMUM_FILE_SIZE = 4_048_576;
const FILE_SIZE_IN_MB = 4;

type FileDropZoneProps = {
  inputId: string;
  onChange: (files: File) => void;
  disabled?: boolean;
};
export function FileDropZone({
  inputId,
  onChange,
  disabled,
}: FileDropZoneProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [dragOver, setDragOver] = useState(false);
  const [, setImgSrc] = useAtom(selectedImageSrcAtom);
  const [, setImgFilename] = useAtom(selectedImageFilenameAtom);

  const onDragOver: JSX.IntrinsicElements["label"]["onDragOver"] = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const onDragLeave = () => setDragOver(false);

  const onDrop: JSX.IntrinsicElements["label"]["onDrop"] = (event) => {
    event.preventDefault();
    if (disabled) {
      return;
    }

    setDragOver(false);

    if (!event.dataTransfer) {
      return;
    }

    if (event.dataTransfer.files[0].size > MAXIMUM_FILE_SIZE) {
      enqueueSnackbar(`ขนาดของไฟล์ต้องไม่เกิน ${FILE_SIZE_IN_MB} MB`, {
        variant: "error",
      });
      return;
    }

    const imageFiles = Array.from(event.dataTransfer.files).filter((file) =>
      file.type.startsWith("image")
    );

    setPreviewImage(imageFiles);
  };

  const onChangeFile: JSX.IntrinsicElements["input"]["onChange"] = (event) => {
    if (!event.target.files) {
      return;
    }

    if (event.target.files[0].size > MAXIMUM_FILE_SIZE) {
      enqueueSnackbar(`ขนาดของไฟล์ต้องไม่เกิน ${FILE_SIZE_IN_MB} MB`, {
        variant: "error",
      });
      return;
    }

    const imageFiles = Array.from(event.target.files).filter((file) =>
      file.type.startsWith("image")
    );

    setPreviewImage(imageFiles);
    event.target.value = "";
  };

  const setPreviewImage = (imageFiles: File[]) => {
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      setImgSrc(reader.result?.toString() || "")
    );
    reader.readAsDataURL(imageFiles[0]);
    setImgFilename(imageFiles[0].name);
    onChange(imageFiles[0]);
  };

  const borderColor = dragOver ? "%23DE2826" : "rgba(158, 158, 158, 0.24)";
  const bgColor = dragOver ? "grey.200" : "grey.50";

  return (
    <Box
      component="label"
      htmlFor={inputId}
      width="200px"
      height="200px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      bgcolor={bgColor}
      borderRadius={1}
      position="relative"
      aria-disabled={disabled}
      sx={{
        pointerEvents: disabled ? "none" : "default",
        cursor: "pointer",
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='${borderColor}' stroke-width='4' stroke-dasharray='16%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
        "& *": {
          pointerEvents: "none",
        },
      }}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <UploadFile color="action" sx={{ width: "102px", height: "102px" }} />
      <Typography variant="caption" color="text.disabled">
        ลากไฟล์ที่ต้องการอัพโหลด
      </Typography>
      <Box
        component="input"
        type="file"
        capture
        accept="image/*"
        id={inputId}
        hidden
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        onChange={onChangeFile}
      />
    </Box>
  );
}
