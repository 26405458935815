import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Box,
  CircularProgress,
  Link,
  DialogActions,
  Button,
  useTheme,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useState } from "react";

import { DataSection } from "@/components/DataSection";
import { formatFullDate, formatProfile, formatTimeRange } from "@/formatter";
import { mapOptional, refetchQueries, useRequireParams } from "@/utils";
import { cancelTrainerBooking, getTrainerSchedule } from "@/services/trainer";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { configs } from "@/configs";

import { ScheduleLabel } from "./ScheduleLabel";
import { useSnackbar } from "notistack";

import type { AxiosErrorWithData } from "@/client/api";
import type { ConfirmDialogProps } from "@/components/ConfirmDialog";

const QUERY_KEY = "trainerPersonalTrainingSchedule";

export function PersonalTrainingScheduleDialog({
  open: isOpen,
  onClose,
  scheduleId,
  fetchKeys = [],
}: {
  open: boolean;
  onClose: () => void;
  scheduleId?: string | null;
  fetchKeys?: string[];
}) {
  const theme = useTheme();
  const { id: trainerId } = useRequireParams(["id"]);
  const [isOpenCancelDialog, setIsOpenCancelDialog] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { data: schedule, isLoading } = useQuery(
    [QUERY_KEY, trainerId, scheduleId],
    () => getTrainerSchedule({ trainerId, scheduleId: scheduleId ?? "" }),
    { enabled: !!scheduleId }
  );

  const booking = schedule?.bookings[0];
  const member = booking?.member;

  const onClickCancel = () => setIsOpenCancelDialog(true);

  const onClickMember = () =>
    navigate(`/trainers/${trainerId}/members/${member?.id ?? ""}`);

  const name = mapOptional(member?.profile, formatProfile) ?? "";

  const columns = mapOptional(schedule, ({ startedAt, endedAt }) => [
    [
      {
        data: [
          {
            key: "1",
            label: "วันที่",
            value: formatFullDate(startedAt),
          },
          {
            key: "2",
            label: "เวลา",
            value: formatTimeRange(startedAt, endedAt),
          },
          {
            key: "3",
            label: "ชื่อสมาชิก",
            value: (
              <Link
                component="button"
                variant="body2"
                color="inherit"
                width="fit-content"
                onClick={onClickMember}
              >
                {name}
              </Link>
            ),
          },
        ],
      },
    ],
  ]) ?? [[]];

  function close() {
    onClose();
    setIsOpenCancelDialog(false);
  }

  const { mutate: cancel, isLoading: isCancelLoading } = useMutation(
    cancelTrainerBooking,
    {
      onSuccess: async () => {
        enqueueSnackbar("ยกเลิกการจองสำเร็จ", { variant: "success" });
        await refetchQueries({ queryClient, fetchKeys });
        close();
      },
      onError: (error: AxiosErrorWithData) => {
        console.error(error);
        enqueueSnackbar(
          error.response?.data.message ?? configs.unknownErrorMessage,
          { variant: "error" }
        );
      },
    }
  );

  const cancelDialog: ConfirmDialogProps = {
    maxWidth: "xs",
    loading: isCancelLoading,
    title: "คุณต้องการยกเลิกการจองใช่หรือไม่",
    confirmMessage: "ใช่",
    cancelMessage: "ไม่ใช่",
    onClose: () => setIsOpenCancelDialog(false),
    onConfirm: () => {
      if (!booking?.id) {
        return;
      }

      cancel({ staffId: +trainerId, bookingId: booking.id });
    },
    open: isOpenCancelDialog,
    reverse: true,
  };

  return (
    <Dialog open={isOpen} maxWidth="sm" onClose={close}>
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        วัน-เวลานัดหมาย
        <Tooltip title="ปิด">
          <IconButton onClick={close} sx={{ ml: "auto" }}>
            <Close />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      {isLoading ? (
        <DialogContent>
          <Box height={190} display="grid" sx={{ placeItems: "center" }}>
            <CircularProgress disableShrink />
          </Box>
        </DialogContent>
      ) : (
        [
          <DialogContent key="content" sx={{ minHeight: 220 }}>
            <ScheduleLabel
              color={theme.palette.warning.main}
              variant="h6"
              mb={3}
            >
              {name}
            </ScheduleLabel>
            <DataSection columns={columns} />
            <Box height="40px" />
          </DialogContent>,
          <DialogActions key="action" sx={{ justifyContent: "unset" }}>
            <Button
              variant="text"
              sx={{
                color: "text.primary",
                ":hover": { backgroundColor: "#0000000A" },
              }}
              onClick={onClickCancel}
            >
              ยกเลิกการจอง
            </Button>
          </DialogActions>,
        ]
      )}
      <ConfirmDialog {...cancelDialog} />
    </Dialog>
  );
}
