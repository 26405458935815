import { useWatch } from "react-hook-form";
import { useQuery } from "react-query";

import { FormMemberAutocomplete } from "@/components/MemberAutocomplete";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { BookingMemberProfile } from "@/components/BookingMemberProfile";
import { getMember, getMemberTodayBookings } from "@/services/member";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

import type { AddTrainerScheduleState } from "@/features/trainer/pages/TrainerProfilePage/AddTrainerScheduleDialog";
import type { AddBookingDialogState } from "@/features/class/pages/ClassScheduleDetailPage/AddBookingDialog";
import type { CheckInDialogState } from "@/features/check-in/components/CheckInDialog";
import type { Control } from "react-hook-form";
import type { StackProps } from "@mui/material";

export type MergeFormMemberProfileControl = Control<
  AddTrainerScheduleState | AddBookingDialogState | CheckInDialogState
>;

export function FormMemberProfileAutocomplete({
  control,
  queryKey,
  ...props
}: {
  control?: MergeFormMemberProfileControl;
  queryKey: string;
} & StackProps) {
  const id = useWatch({ name: "member.id", control });

  const { data, isLoading: memberLoading } = useQuery(
    [queryKey, "member", id],
    () => getMember(id),
    { enabled: !!id }
  );

  const { data: bookings, isLoading: bookingLoading } = useQuery(
    [queryKey, "bookings", id],
    () => getMemberTodayBookings(id),
    { enabled: !!id }
  );

  const loadingQuery = memberLoading || bookingLoading;

  const formHeight = 172;

  return (
    <Stack gap={5} mt={-1} {...props}>
      <Box
        display="grid"
        gridTemplateColumns="50px 1fr"
        gap={3}
        alignItems="center"
        position="absolute"
        width="calc(100% - 48px)"
        height={formHeight}
        zIndex={1000}
        bgcolor="background.paper"
      >
        <QrCodeScannerIcon fontSize="large" />
        <Typography variant="subtitle1">
          สแกนคิวอาร์โค้ด เพื่อตรวจสอบข้อมูลของสมาชิก
        </Typography>
        <FormMemberAutocomplete
          label="หมายเลขสมาชิก ชื่อ หรือเบอร์โทรศัพท์"
          name="member"
          control={control}
          sx={{ gridColumn: "1/-1" }}
          required
        />
      </Box>
      <Box height={formHeight} />
      {loadingQuery ? (
        <Box display="grid" sx={{ placeItems: "center" }} height={544}>
          <CircularProgress disableShrink />
        </Box>
      ) : (
        data && <BookingMemberProfile member={data} bookings={bookings} />
      )}
    </Stack>
  );
}
