import { TextField } from "@mui/material";
import { useController } from "react-hook-form";
import { useQuery } from "react-query";
import { getStaffCode } from "@/services/staff";

import type { Control } from "react-hook-form";
import type { StaffEditorState } from "./StaffEditor";

export function StaffCodeFormTextField({
  control,
}: {
  control?: Control<StaffEditorState>;
}) {
  const { field } = useController({ name: "staffCode", control });

  const { data, isLoading } = useQuery(["memberCode"], () => getStaffCode(), {
    enabled: !field.value,
    cacheTime: 0,
  });

  const proxy = field.value
    ? field.value
    : isLoading
    ? "Loading..."
    : data ?? "";

  return <TextField value={proxy} label="รหัสประจำตัว" disabled />;
}
