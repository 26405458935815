import { api } from "@/client/api";
import { configs } from "@/configs";
import { parseDateTimeFromPrisma, parseURLSearchParams } from "@/utils";

import type {
  Facility,
  FacilityBooking,
  FacilityBookingStatus,
} from "@/models";

type GetFacilityBookingsInput = {
  facilityId: string;
  status?: FacilityBookingStatus;
};

type UpdateBookingRequestInput = {
  bookingId: string;
  status: FacilityBookingStatus;
};

type CreateFacilityInput = Omit<Facility, "id" | "currentJoined">;

type UpdateFacilityInput = Partial<CreateFacilityInput> & { id: string };

type GetFacilities = {
  data: Facility[];
  total: number;
};

type GetFacilityBookings = {
  data: FacilityBooking[];
  total: number;
};

export async function getFacilities(query = "") {
  const { data } = await api.get<GetFacilities>(
    `${configs.apiUrl}/facilities?${query}`
  );
  return parseDateTimeFromPrisma(data) as GetFacilities;
}

export async function getFacility(id: string) {
  const { data } = await api.get<Facility>(
    `${configs.apiUrl}/facilities/${id}`
  );
  return parseDateTimeFromPrisma(data) as Facility;
}

export async function createFacility(
  data: CreateFacilityInput
): Promise<Facility> {
  const response = await api.post(`${configs.apiUrl}/facilities`, data);

  return parseDateTimeFromPrisma(response.data) as Facility;
}

export async function updateFacility({
  id,
  ...data
}: UpdateFacilityInput): Promise<Facility> {
  const response = await api.patch(`${configs.apiUrl}/facilities/${id}`, data);

  return parseDateTimeFromPrisma(response.data) as Facility;
}

export async function deleteFacility(id: string) {
  const { data } = await api.delete<Facility>(
    `${configs.apiUrl}/facilities/${id}`
  );
  return data;
}

export async function getFacilityBookings({
  facilityId,
  status,
}: GetFacilityBookingsInput): Promise<GetFacilityBookings> {
  const query = status ? parseURLSearchParams({ status }) : "";

  const { data } = await api.get<FacilityBooking[]>(
    `${configs.apiUrl}/facilities/${facilityId}/bookings?${query}`
  );

  return parseDateTimeFromPrisma(data) as GetFacilityBookings;
}

export async function updateBookingRequest({
  bookingId,
  status,
}: UpdateBookingRequestInput) {
  const response = await api.patch(
    `${configs.apiUrl}/facilities/bookings/${bookingId}`,
    { status }
  );
  return response.data as FacilityBookingStatus;
}
