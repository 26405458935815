import {
  Box,
  Card,
  CardContent,
  Stack,
  Button,
  TableContainer,
  Table,
  TableHead,
  Breadcrumbs,
  Typography,
  MenuItem,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { useAtom } from "jotai";

import { getDocumentTitle } from "@/utils";
import { getStaffs } from "@/services/staff";

import { QueryTextField } from "@/components/QueryTextField";
import { DataTableBody } from "@/components/DataTableBody";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { SearchParamsSelect } from "@/components/Select";
import { StaffTableRow, StaffTableRowHeader } from "./StaffTableRow";
import { RemoveStaffDialog } from "../../components/RemoveStaffDialog";
import { AddStaffDialog } from "./AddStaffDialog";
import { EditStaffDialog } from "./EditStaffDialog";
import { RoleType } from "@/models";
import { selectedBranchAtom } from "@/atom/global";

type RoleOptions = {
  label: string;
  value: string | number;
};

export function StaffListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedBranch] = useAtom(selectedBranchAtom);
  const dialog = searchParams.get("dialog");
  const id = searchParams.get("id") ?? "";
  const QUERY_KEY = `staffs_${id}`;
  const query = searchParams.toString();

  const { data: raw, isLoading } = useQuery(
    [QUERY_KEY, query, selectedBranch],
    () => getStaffs(query)
  );

  const data = raw?.data ?? [];
  const total = raw?.total;

  const positionOptions: RoleOptions[] = [
    { label: "ทั้งหมด", value: "" },
    { label: RoleType.Admin, value: 1 },
    { label: RoleType.Manager, value: 3 },
    { label: RoleType.Staff, value: 99 },
  ];
  const roleOptions: RoleOptions[] = [
    { label: "ทั้งหมด", value: "" },
    { label: RoleType.Trainer, value: 2 },
    { label: RoleType.Sale, value: 4 },
  ];

  const editData = data?.find((item) => item.id.toString() === id);

  function onCloseDialog() {
    searchParams.delete("dialog");
    searchParams.delete("id");
    setSearchParams(searchParams, { replace: true });
  }

  function add() {
    searchParams.set("dialog", "user-add");
    setSearchParams(searchParams);
  }

  const addDialog = {
    open: dialog === "user-add",
    onClose: onCloseDialog,
  };

  const editDialog = {
    open: typeof editData !== "undefined" && dialog === "edit",
    onClose: onCloseDialog,
    id,
    data: editData,
    fetchKey: QUERY_KEY,
  };

  const removeDialog = {
    open: dialog === "remove",
    onClose: onCloseDialog,
    id,
  };

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle("Staff")}</title>
      </Helmet>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Typography color="text.primary">พนักงาน</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack direction="row" alignItems="center" gap={2} mb={5}>
            <QueryTextField
              sx={{ width: 400 }}
              placeholder="ค้นหาด้วยรหัสประจำตัว, ชื่อ, อีเมล"
            />
            <SearchParamsSelect
              sx={{ width: 200 }}
              name="position"
              label="ตำแหน่ง"
              displayEmpty
            >
              {positionOptions.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </SearchParamsSelect>
            <SearchParamsSelect
              sx={{ width: 200 }}
              name="role"
              label="หน้าที่"
              displayEmpty
            >
              {roleOptions.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </SearchParamsSelect>
            <Button
              variant="contained"
              size="large"
              onClick={add}
              sx={{ ml: "auto" }}
            >
              เพิ่มพนักงาน
            </Button>
          </Stack>
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <StaffTableRowHeader />
              </TableHead>
              <DataTableBody loading={isLoading} data={data}>
                {data.map((item) => (
                  <StaffTableRow key={item.id} data={item} />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
      <AddStaffDialog {...addDialog} />
      <EditStaffDialog {...editDialog} />
      <RemoveStaffDialog {...removeDialog} />
    </Box>
  );
}
