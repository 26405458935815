import {
  Card,
  CardContent,
  Stack,
  ButtonGroup,
  Button,
  TableContainer,
  Table,
  TableHead,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import { mapOptional } from "@/utils";
import { TrainerContract } from "@/models";
import { formatTrainerContract } from "@/formatter";
import { getTrainers } from "@/services/trainer";
import { DataTableBody } from "@/components/DataTableBody";
import { QueryTextField } from "@/components/QueryTextField";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";

import { TrainerTableRowHeader, TrainerTableRow } from "./TrainerTableRow";

export function TrainerList() {
  const [searchParams, setSearchParams] = useSearchParams();

  const query = searchParams.toString();
  const contract = searchParams.get("contract") as TrainerContract | undefined;

  const setContract = (c: TrainerContract | null) => () => {
    if (c) {
      searchParams.set("contract", c);
    } else {
      searchParams.delete("contract");
    }

    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const { data: raw, isLoading } = useQuery(["trainers", query], () =>
    getTrainers(query)
  );

  const data = raw?.data ?? [];
  const total = raw?.total;

  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={4}
            mb={5}
          >
            <QueryTextField
              sx={{ width: 600 }}
              placeholder="ค้นหาด้วยรหัสประจำตัวหรือชื่อ"
            />
            <ButtonGroup variant="contained" size="large" color="inherit">
              {[null, TrainerContract.Employee, TrainerContract.Freelance].map(
                (c) => (
                  <Button
                    key={c}
                    onClick={setContract(c)}
                    sx={{ bgcolor: c === contract ? "grey.100" : "grey.300" }}
                  >
                    {mapOptional(c, formatTrainerContract) ?? "ทั้งหมด"}
                  </Button>
                )
              )}
            </ButtonGroup>
          </Stack>
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <TrainerTableRowHeader />
              </TableHead>
              <DataTableBody loading={isLoading} data={data}>
                {data.map((item) => (
                  <TrainerTableRow key={item.id} data={item} />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
    </>
  );
}
