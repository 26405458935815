import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Box,
  CircularProgress,
  useTheme,
  Typography,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";

import { mapOptional } from "@/utils";
import { formatFullDate, formatProfile, formatTimeRange } from "@/formatter";
import { DataSection } from "@/components/DataSection";
import { getSchedule } from "@/services/schedule";

export function ClassScheduleDialog({
  open: isOpen,
  onClose,
  scheduleId,
}: {
  open: boolean;
  onClose: () => void;
  scheduleId?: string | null;
}) {
  const { id: trainerId } = useParams();
  const theme = useTheme();
  const { data: schedule, isLoading } = useQuery(
    ["trainerClassSchedule", scheduleId],
    () => getSchedule({ scheduleId: scheduleId ?? "" }),
    { enabled: !!scheduleId }
  );

  const classId = schedule?.class?.id ?? "";

  const seeMoreDetailPath = trainerId
    ? `/trainers/${trainerId}/schedules/${scheduleId ?? ""}`
    : `/classes/${classId}/schedules/${scheduleId ?? ""}`;

  const name = schedule?.class?.name ?? "";

  const columns = mapOptional(
    schedule,
    ({
      startedAt,
      endedAt,
      staffs,
      location,
      capacity,
      description,
      bookings,
    }) => {
      const activeBookingsCount = bookings.filter(
        ({ cancelledAt }) => !cancelledAt
      ).length;
      const waitingBookingsCount = bookings.filter(
        ({ isWaiting }) => isWaiting
      ).length;
      const bookingCount =
        activeBookingsCount > capacity ? capacity : activeBookingsCount;
      return [
        [
          {
            data: [
              {
                label: "วันที่",
                key: "date",
                value: formatFullDate(startedAt),
              },
              {
                label: "เวลา",
                key: "time",
                value: formatTimeRange(startedAt, endedAt),
              },
              {
                label: "ผู้สอน",
                key: "staff",
                value: mapOptional(staffs?.at(0)?.profile, formatProfile),
              },
              { label: "สถานที่", key: "location", value: location },
              {
                label: "ผู้จอง",
                key: "booking",
                value: `${bookingCount}/${capacity} คน`,
              },
              {
                label: "รอคิว",
                key: "waiting",
                value: waitingBookingsCount,
              },
              {
                label: "สถานะ",
                key: "status",
                value: (
                  <Typography
                    variant="body2"
                    color={
                      bookingCount >= capacity
                        ? theme.palette.error.main
                        : theme.palette.success.main
                    }
                  >
                    {bookingCount >= capacity ? "จองเต็ม" : "มีที่ว่าง"}
                  </Typography>
                ),
              },
              { label: "รายละเอียด", value: description },
            ],
          },
        ],
      ];
    }
  ) ?? [[]];

  return (
    <Dialog open={isOpen} maxWidth="sm" onClose={onClose}>
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        รายละเอียด
        <Tooltip title="ปิด">
          <IconButton onClick={onClose} sx={{ ml: "auto" }}>
            <Close />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      {isLoading ? (
        <DialogContent>
          <Box height={386} display="grid" sx={{ placeItems: "center" }}>
            <CircularProgress disableShrink />
          </Box>
        </DialogContent>
      ) : (
        [
          <DialogContent key="content" sx={{ minHeight: 324 }}>
            <Typography variant="h6" mb={3}>
              {name}
            </Typography>
            <DataSection columns={columns} />
            <Box height="40px" />
          </DialogContent>,
          <DialogActions key="action" sx={{ justifyContent: "unset" }}>
            <Button component={Link} to={seeMoreDetailPath}>
              ดูรายละเอียดเพิ่มเติม
            </Button>
          </DialogActions>,
        ]
      )}
    </Dialog>
  );
}
