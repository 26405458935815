import { getDashboardUrl } from "@/services/dashboard";
import {
  Card,
  CardContent,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import IframeResizer from "iframe-resizer-react";
import { useQuery } from "react-query";
import ErrorIcon from "@mui/icons-material/Error";
import { selectedBranchAtom } from "@/atom/global";
import { useAtom } from "jotai";

type DashboardCanvasProps = {
  title: string;
  type: string;
};

export const DashboardCanvas = ({ title, type }: DashboardCanvasProps) => {
  const [selectedBranch] = useAtom(selectedBranchAtom);
  const {
    data: raw,
    isError,
    error,
    isLoading,
  } = useQuery([title, selectedBranch], () => getDashboardUrl(type));

  if (isLoading) {
    return (
      <Card>
        <CardContent>
          <Stack
            height="80vh"
            gap={2}
            overflow="hidden"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress disableShrink />
          </Stack>
        </CardContent>
      </Card>
    );
  }

  if (isError) {
    return (
      <Card>
        <CardContent>
          <Stack
            height="80vh"
            gap={2}
            overflow="hidden"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Stack spacing={2} alignItems="center">
              <ErrorIcon sx={{ fontSize: 40 }} />
              <Typography variant="body1" align="center">
                An error has occurred
                <br /> {String((error as AxiosError).message)}
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent>
        <IframeResizer
          title={title}
          src={raw?.data}
          checkOrigin={false}
          tabIndex={0}
          style={{
            border: "0px",
            width: "1px",
            minWidth: "100%",
            minHeight: "80vh",
          }}
          loading="eager"
        />
      </CardContent>
    </Card>
  );
};
