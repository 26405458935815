import { MenuItem } from "@mui/material";
import { useQuery } from "react-query";
import * as yup from "yup";

import { FormSelect } from "@/components/Select";
import { PermissionGroupType } from "@/models";
import { getPermissionGroups } from "@/services/permissionGroup";
import { parseURLSearchParams } from "@/utils";

import type { FormSelectProps } from "@/components/Select";
import type { ChangePermissionEditorState } from "./ChangePermissionEditor";

type FormPermissionGroupSelectProps =
  FormSelectProps<ChangePermissionEditorState> & {
    type: PermissionGroupType;
  };

export function FormPermissionGroupSelect({
  type,
  ...props
}: FormPermissionGroupSelectProps) {
  const query = parseURLSearchParams({ type });

  const { data, isLoading } = useQuery(["getPermissionGroupSelect", type], () =>
    getPermissionGroups(query)
  );

  const options = data?.map(({ id, name }) => ({ id, name })) ?? [];

  return !isLoading ? (
    <FormSelect {...props}>
      {options.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </FormSelect>
  ) : (
    <></>
  );
}

const schema = yup.number().required();

FormPermissionGroupSelect.schema = schema;
