import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { getFacilityBookings, updateBookingRequest } from "@/services/facility";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { DataTableBody } from "@/components/DataTableBody";
import { formatFacilityBookingStatus } from "@/formatter";
import { mapOptional, refetchQueries, useRequireParams } from "@/utils";
import { FacilityBookingStatus } from "@/models";

import {
  FacilityBookingTableRow,
  FacilityBookingTableRowHeader,
} from "./FacilityBookingTableRow";
import { FacilityBookingDialog } from "./FacilityBookingDialog";
import { AxiosErrorWithData } from "@/client/api";
import { ConfirmDialogProps } from "@/components/ConfirmDialog";
import { configs } from "@/configs";
import { useSnackbar } from "notistack";

const QUERY_KEY = "getFacilityBookings";
export const FACILITY_BOOKINGS_QUERY_KEY = "facilityBooking";
export const FACILITY_BOOKINGS_STATUS_QUERY_KEY = "facilityBookingsStatus";

export function FacilityBookingList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { id: facilityId } = useRequireParams(["id"]);

  const status = searchParams.get("status") as
    | FacilityBookingStatus
    | undefined;

  const { data: raw, isLoading } = useQuery([QUERY_KEY, status], () =>
    getFacilityBookings({ facilityId, status })
  );

  const data = raw?.data ?? [];
  const total = raw?.total;
  const filters = [
    null,
    FacilityBookingStatus.Pending,
    FacilityBookingStatus.Approved,
    FacilityBookingStatus.Rejected,
    FacilityBookingStatus.Cancelled,
  ];

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: cancel, isLoading: cancelLoading } = useMutation(
    updateBookingRequest,
    {
      onSuccess: async () => {
        enqueueSnackbar("ยกเลิกการจองสำเร็จ", { variant: "success" });
        await refetchQueries({
          queryClient,
          fetchKeys: [
            FACILITY_BOOKINGS_QUERY_KEY,
            FACILITY_BOOKINGS_STATUS_QUERY_KEY,
          ],
        });
        onCloseDialog();
      },
      onError: (error: AxiosErrorWithData) => {
        console.error(error);
        enqueueSnackbar(
          error.response?.data.message ?? configs.unknownErrorMessage,
          { variant: "error" }
        );
      },
    }
  );

  const onClickStatus = (s: FacilityBookingStatus | null) => {
    if (s) {
      searchParams.set("status", s);
    } else {
      searchParams.delete("status");
    }

    searchParams.delete("page");
    setSearchParams(searchParams, { replace: true });
  };

  const dialog = searchParams.get("dialog");
  const bookingId = searchParams.get("booking-id");

  const facilityBookingDialog = {
    open: dialog === "booking-detail",
    onClose: () => {
      searchParams.delete("dialog");
      searchParams.delete("booking-id");
      setSearchParams(searchParams, { replace: true });
    },
    data: data.find(({ id }) => id.toString() === bookingId),
    fetchKeys: [QUERY_KEY],
  };

  function addBooking() {
    searchParams.set("dialog", "booking-add");
    setSearchParams(searchParams);
  }

  function onCloseDialog() {
    searchParams.delete("dialog");
    searchParams.delete("bookingId");
    setSearchParams(searchParams, { replace: true });
  }

  const addBookingDialog = {
    open: dialog === "booking-add",
    onClose: onCloseDialog,
    fetchKeys: [
      FACILITY_BOOKINGS_QUERY_KEY,
      FACILITY_BOOKINGS_STATUS_QUERY_KEY,
    ],
  };

  const cancelDialog: ConfirmDialogProps = {
    maxWidth: "xs",
    loading: cancelLoading,
    title: "คุณต้องการยกเลิกการจองใช่หรือไม่",
    confirmMessage: "ใช่",
    cancelMessage: "ไม่ใช่",
    onClose: onCloseDialog,
    onConfirm: () => {
      if (!bookingId) {
        return;
      }

      alert("TODO: cancel booking");
      // cancel({ facilityId, scheduleId, bookingId });
    },
    open: dialog === "cancel" && !!bookingId,
  };

  return (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={6}
        >
          <ButtonGroup variant="contained" size="large" color="inherit">
            {filters.map((s) => (
              <Button
                key={s}
                onClick={() => onClickStatus(s)}
                sx={{ bgcolor: s === status ? "grey.100" : "grey.300" }}
              >
                {mapOptional(s, formatFacilityBookingStatus) ?? "ทั้งหมด"}
              </Button>
            ))}
          </ButtonGroup>
          {/* <Button variant="contained" size="large" onClick={addBooking}>
            เพิ่มการจอง
          </Button> */}
        </Stack>
        <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
          <Table stickyHeader>
            <TableHead>
              <FacilityBookingTableRowHeader />
            </TableHead>
            <DataTableBody
              loading={isLoading}
              data={data}
              searchKeys={["status"]}
            >
              {data.map((item) => (
                <FacilityBookingTableRow key={item.id} data={item} />
              ))}
            </DataTableBody>
          </Table>
        </TableContainer>
        <SearchParamsPagination total={total} />
      </CardContent>
      <FacilityBookingDialog {...facilityBookingDialog} />
    </Card>
  );
}
