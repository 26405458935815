import {
  Box,
  Breadcrumbs,
  Card,
  CircularProgress,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";

import { Helmet } from "react-helmet-async";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, Outlet } from "react-router-dom";

import { getDocumentTitle, useRequireParams } from "@/utils";

import { CategoryDetail } from "./CategoryDetail";
import { getCategory } from "@/services/category";
import { VideoList } from "@/features/video/components/VideoList";

export function CategoryDetailPage() {
  const { id } = useRequireParams(["id"]);

  const { data: categoryData, isLoading } = useQuery("category-detail", () =>
    getCategory(id)
  );

  const isLoaded = !isLoading && typeof categoryData !== "undefined";
  const title = "ประเภทกิจกรรม";

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle(title)}</title>
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <MuiLink component={Link} color="text.secondary" to="/categories">
          ประเภทกิจกรรม
        </MuiLink>
        <Typography color="text.primary">รายละเอียดประเภทกิจกรรม</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 2.5 }}>
        {isLoaded ? (
          <CategoryDetail data={categoryData} />
        ) : (
          <Box display="grid" sx={{ placeItems: "center" }} height={436}>
            <CircularProgress disableShrink />
          </Box>
        )}
      </Card>
      <VideoList />
      <Outlet />
    </Box>
  );
}
