import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Card,
  CircularProgress,
  Tab,
  Tabs,
  TabsProps,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";
import { getFitness } from "@/services/fitness";
import { getDocumentTitle } from "@/utils";

import { FitnessDetail } from "./FitnessDetail";

const QUERY_KEY = "getFitness";

export function FitnessDetailPage() {
  const { fitnessCentersId } = useCurrentUser();
  const location = useLocation();
  const navigate = useNavigate();

  const path = location?.pathname ?? "";

  const { data, isLoading } = useQuery([QUERY_KEY], () =>
    getFitness(fitnessCentersId?.toString() ?? "")
  );

  const onChangeTab: TabsProps["onChange"] = (_, value: string) => {
    navigate(value, { replace: true });
  };

  const isShowPermissionGroup = !!data?.hasPermissionGroupControl;

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle("Manage Fitness")}</title>
      </Helmet>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Typography color="text.primary">จัดการฟิตเนส</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 2.5 }}>
        {!isLoading && data ? (
          <FitnessDetail data={data} fetchKeys={[QUERY_KEY]} />
        ) : (
          <Box display="grid" sx={{ placeItems: "center" }} height={436}>
            <CircularProgress disableShrink />
          </Box>
        )}
      </Card>
      <Tabs value={path} onChange={onChangeTab} sx={{ mb: 3 }}>
        <Tab label="สาขา" value="/fitness" sx={{ width: 180 }} />
        <Tab
          label="บัตรสมาชิก"
          value="/fitness/member-card"
          sx={{ width: 180 }}
        />
        {isShowPermissionGroup && (
          <Tab
            label="ตั้งค่าสิทธิ์การใช้งาน"
            sx={{ width: 180 }}
            value={`/fitness/permission-group`}
          />
        )}
      </Tabs>
      <Outlet />
    </Box>
  );
}
