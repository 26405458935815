import { useForm, useWatch, Controller, useController } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import {
  Box,
  FormControlLabel,
  MenuItem,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import { Fragment, useEffect } from "react";

import { datetime, DateTimeSchema } from "@/lib/yup";
import { now } from "@/lib/dateTime";
import { FormTextField } from "@/components/FormTextField";
import { formatTime } from "@/formatter";
import { FormSelect } from "@/components/Select";
import { FormTimeTextField } from "@/components/FormTimeTextField";
import { FormNumericTextField } from "@/components/NumericTextField";
import { FormRadioGroup } from "@/components/FormRadioGroup";
import { FormDatePicker } from "@/components/DatePicker";
import { timeAfter } from "@/lib/yup/validation";
import { setTime } from "@/utils";

import { FormTrainerAutocomplete } from "./TrainerAutocomplete";

import type { TrainerAutocompleteValue } from "./TrainerAutocomplete";
import type { InferType } from "yup";
import type { ControllerProps } from "react-hook-form";

export type ScheduleEditorState = Omit<
  InferType<typeof schema>,
  "scheduleType" | "trainers" | "endTime"
> & {
  scheduleType: "now" | "schedule";
  trainers: TrainerAutocompleteValue[];
  endTime: string;
};

type ScheduleEditorProps = Pick<
  ControllerProps<ScheduleEditorState>,
  "control"
>;

export function ScheduleEditor({ control }: ScheduleEditorProps) {
  const { field } = useController({ name: "trainers", control });

  const startTime = useWatch({ name: "startTime", control });
  const {
    field: { onChange },
  } = useController({ name: "endTime", control });

  useEffect(() => {
    const start = startTime.split(":");
    start[0] = `${+start[0] + 1}`;
    onChange(start.join(":"));
  }, [startTime, onChange]);

  function resetTrainers() {
    field.onChange([]);
  }

  return (
    <Stack gap={2.5}>
      <Typography variant="labelLarge">วันที่และเวลาเปิดจอง</Typography>
      <FormRadioGroup row name="scheduleType" control={control}>
        <FormControlLabel
          value="now"
          control={<Radio />}
          label="เปิดให้จองทันที"
        />
        <FormControlLabel
          value="schedule"
          control={<Radio />}
          label="กำหนดวันที่และเวลาเปิดจอง"
        />
      </FormRadioGroup>
      <Controller
        render={({ field }) =>
          field.value === "schedule" ? (
            <Box display="grid" gridTemplateColumns="220px 220px" gap={2.5}>
              <FormDatePicker
                disablePast
                label="วันที่"
                name="openBookDate"
                control={control}
                TextFieldProps={{ required: true }}
              />
              <FormTimeTextField
                label="เวลาเปิดจอง"
                id="openBookTime"
                name="openBookTime"
                control={control}
                required
              />
            </Box>
          ) : (
            <Fragment />
          )
        }
        name="scheduleType"
        control={control}
      />
      <Typography variant="labelLarge">
        วันที่และเวลาเริ่ม-สิ้นสุดคลาส
      </Typography>
      <Box display="grid" gridTemplateColumns="220px 220px 7px 220px" gap={2.5}>
        <FormDatePicker
          disablePast
          label="วันที่"
          name="startDate"
          control={control}
          onChange={resetTrainers}
          TextFieldProps={{ required: true }}
        />
        <FormTimeTextField
          label="เวลาเริ่ม"
          id="startTime"
          name="startTime"
          control={control}
          onChange={resetTrainers}
          required
        />
        <Typography alignSelf="flex-start" mt={1.75}>
          -
        </Typography>
        <FormTimeTextField
          label="เวลาสิ้นสุด"
          id="endTime"
          name="endTime"
          control={control}
          onChange={resetTrainers}
          required
        />
      </Box>
      <Box display="grid" gridTemplateColumns="1fr 236px" gap={2.5}>
        <FormTextField
          fullWidth
          label="สถานที่"
          name="location"
          control={control}
          required
        />
        <FormNumericTextField
          fullWidth
          label="ผู้เข้าร่วมสูงสุด"
          name="capacity"
          control={control}
          required
        />
        <ScheduleEditorTrainerAutocomplete control={control} />
      </Box>
      <FormTextField
        multiline
        rows={4}
        label="รายละเอียด"
        name="description"
        control={control}
      />
      <Stack gap={1}>
        <Typography variant="labelLarge">
          ยกเลิกการจอง (ก่อนเวลาเริ่มคลาส)
        </Typography>
        <FormSelect
          name="cancelBeforeTime"
          control={control}
          sx={{ width: 220 }}
        >
          <MenuItem value={1}>1 ชั่วโมง</MenuItem>
          <MenuItem value={2}>2 ชั่วโมง</MenuItem>
          <MenuItem value={3}>3 ชั่วโมง</MenuItem>
        </FormSelect>
      </Stack>
    </Stack>
  );
}

export function useScheduleEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  id: yup.string(),
  scheduleType: yup.string(),
  openBookDate: datetime().when("scheduleType", {
    is: "schedule",
    then: (schema: DateTimeSchema) => schema.required(),
  }) as DateTimeSchema,
  openBookTime: yup
    .string()
    .label("เวลาเปิดจอง")
    .when("scheduleType", {
      is: "schedule",
      then: (schema) => schema.required(),
    }) as yup.StringSchema,
  startDate: datetime().required("ระบุวันที่"),
  startTime: yup.string().label("เวลาเริ่ม").required(),
  endTime: timeAfter(
    "startTime",
    "End time must be after ${startTime}",
    yup.string().label("เวลาสิ้นสุด").required().time()
  ),
  location: yup.string().label("สถานที่").required(),
  capacity: yup
    .number()
    .label("ผู้เข้าร่วมสูงสุด")
    .required()
    .min(1, "ระบุตัวเลขมากกว่า ${min}"),
  trainers: yup.array().min(1, "ระบุผู้สอน"),
  description: yup.string().label("รายละเอียด").max(500),
  cancelBeforeTime: yup.number().required(),
});

const resolver = yupResolver(schema);

const defaultValues = {
  scheduleType: "now",
  openBookDate: now().startOf("day"),
  openBookTime: formatTime(now().startOf("hour")),
  startDate: now().startOf("day"),
  startTime: formatTime(now().startOf("hour")),
  endTime: formatTime(now().startOf("hour").plus({ hour: 1 })),
  trainers: [] as TrainerAutocompleteValue[],
  cancelBeforeTime: 1,
} as ScheduleEditorState;

function ScheduleEditorTrainerAutocomplete({ control }: ScheduleEditorProps) {
  const startDate = useWatch({ name: "startDate", control });
  const startTime = useWatch({ name: "startTime", control });
  const endTime = useWatch({ name: "endTime", control });
  const id = useWatch({ name: "id", control });

  const start = setTime(startDate, startTime);
  const end = setTime(startDate, endTime);

  return (
    <FormTrainerAutocomplete
      label="ผู้สอน"
      name="trainers"
      control={control}
      start={start}
      end={end}
      excludeScheduleId={id}
      required
    />
  );
}
