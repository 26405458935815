import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";

import { getDocumentTitle } from "@/utils";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { QueryTextField } from "@/components/QueryTextField";
import { DataTableBody } from "@/components/DataTableBody";
import { getSales } from "@/services/sale";

import { SaleTableRow, SaleTableRowHeader } from "./SaleTableRow";

export function SaleListPage() {
  const [searchParams] = useSearchParams();

  const query = searchParams.toString();

  const { data: raw, isLoading } = useQuery(["sales", query], () =>
    getSales(query)
  );

  const data = raw?.data ?? [];
  const total = raw?.total;

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle("Sale")}</title>
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <Typography color="text.primary">ฝ่ายขาย</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={4}
            mb={5}
          >
            <QueryTextField
              sx={{ width: 600 }}
              placeholder="ค้นหาด้วยรหัสประจำตัวหรือชื่อ"
            />
          </Stack>
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <SaleTableRowHeader />
              </TableHead>
              <DataTableBody loading={isLoading} data={data}>
                {data.map((item) => (
                  <SaleTableRow key={item.id} data={item} />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
    </Box>
  );
}
