import { Autocomplete, TextField } from "@mui/material";
import { useController } from "react-hook-form";
import { useQuery } from "react-query";
import * as yup from "yup";

import { getOpenProducts } from "@/services/product";

import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";
import type { AutocompleteProps as MuiAutocompleteProps } from "@mui/material/Autocomplete/Autocomplete";
import type { TextFieldProps } from "@mui/material";

export type ProductAutocompleteValue = {
  id: number;
  name: string;
  price: number;
};

export type ProductAutocompleteProps = {
  label?: string;
  TextFieldProps?: Partial<TextFieldProps>;
} & Omit<
  MuiAutocompleteProps<ProductAutocompleteValue, false, false, false>,
  "renderInput" | "options"
>;

function useProducts() {
  return useQuery("autocomplete-products", () => getOpenProducts("limit=1000"));
}

export function ProductAutocomplete({
  label,
  TextFieldProps,
  ...props
}: ProductAutocompleteProps) {
  const { data: raw, isLoading } = useProducts();
  const data = raw?.data ?? [];
  const options = data.map(({ id, name, price }) => ({
    id,
    name,
    price,
  }));

  return (
    <Autocomplete
      options={options}
      loading={isLoading}
      getOptionLabel={({ name }) => name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField label={label} {...params} {...TextFieldProps} />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      {...props}
    />
  );
}

export function FormProductAutocomplete<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  required = false,
  TextFieldProps,
  ...props
}: { required?: boolean } & Pick<
  ControllerProps<TFieldValues, TName>,
  "name" | "control"
> &
  Omit<ProductAutocompleteProps, "value" | "name" | "onChange">) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <ProductAutocomplete
      value={field.value ?? null}
      onChange={(_, newValue) => field.onChange(newValue)}
      TextFieldProps={{
        inputRef: field.ref,
        onBlur: field.onBlur,
        error: fieldState.invalid,
        helperText: fieldState.error?.message,
        required,
        ...TextFieldProps,
      }}
      {...props}
    />
  );
}

const schema = yup
  .object({
    id: yup.number().required(),
    name: yup.string().required(),
    price: yup.number().required(),
  })
  .default(null) // fix yup not validate when value is undefined
  .nullable();

ProductAutocomplete.schema = schema;
