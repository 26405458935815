import {
  Box,
  Card,
  CardContent,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TextField,
  Typography,
  Grid,
  Divider,
  Backdrop,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { getApiErrorMessage, useRequireParams } from "@/utils";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useSnackbar } from "notistack";

import { getSalesList } from "@/services/sale";
import { QueryTextField } from "@/components/QueryTextField";
import { ItemsTotal } from "@/components/ItemsTotal";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { DataTableBody } from "@/components/DataTableBody";
import { now } from "@/lib/dateTime";
import { formatPrice } from "@/formatter";
import { AxiosErrorWithData } from "@/client/api";
import { getReceiptPDF } from "@/services/receipts";
import { CircularLoading } from "@/components/CircularLoading";
import { CancelReceiptDialog } from "@/features/member/pages/TransactionListPage/CancelReceiptDialog";

import { SalesTableRowHeader, SalesTableRow } from "./SalesTableRow";

import type { DateTime } from "luxon";

const QUERY_KEY = "customer";

export function SalesListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const { saleId: id } = useRequireParams(["saleId"]);
  const [start, setStart] = useState<DateTime>(
    now().minus({
      month: 1,
    })
  );
  const [end, setEnd] = useState<DateTime>(now());

  const { mutate: generatePDF, isLoading: isPDFLoading } = useMutation(
    getReceiptPDF,
    {
      onSuccess: (url) => {
        window.open(url, "_blank");
      },
      onError: (error: AxiosErrorWithData) => {
        console.error(error);
        enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
      },
    }
  );

  const handleChangeStart = (newValue: DateTime | null) => {
    if (newValue) setStart(newValue);
  };
  const handleChangeEnd = (newValue: DateTime | null) => {
    if (newValue) setEnd(newValue);
  };
  const query = searchParams.toString();

  const dateRangeQuery = new URLSearchParams({
    startAt: start.startOf("day").toISO(),
    endAt: end.endOf("day").toISO(),
  });

  const { data: raw, isLoading } = useQuery(
    [QUERY_KEY, query, start, end],
    () => getSalesList(+id, `${query}&${dateRangeQuery.toString()}`)
  );

  const dialog = searchParams.get("dialog");
  const cancelReceiptId = searchParams.get("cancelReceiptId") ?? "";
  const showMsg = !!searchParams.get("showMsg");

  function onCloseDialog() {
    searchParams.delete("dialog");
    searchParams.delete("cancelReceiptId");
    searchParams.delete("showMsg");
    setSearchParams(searchParams, { replace: true });
  }

  const cancelReceiptDialog = {
    open: dialog === "receipt-cancel",
    id: cancelReceiptId,
    onClose: onCloseDialog,
    showMsg,
    fetchKeys: [QUERY_KEY],
  };

  const data = raw?.data ?? [];
  const total = raw?.total;
  const membershipTotal = raw?.membershipTotal ?? 0;
  const ptTotal = raw?.ptTotal ?? 0;
  const productTotal = raw?.productTotal ?? 0;

  return (
    <Box>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack>
            <Stack direction="row" gap={4} mb={5}>
              <DatePicker
                label="ตั้งแต่"
                value={start}
                inputFormat="dd/MM/yyyy"
                minDate={end.minus({
                  year: 1,
                })}
                maxDate={end}
                onChange={handleChangeStart}
                renderInput={(params) => <TextField {...params} />}
              />

              <DatePicker
                label="ถึง"
                value={end}
                inputFormat="dd/MM/yyyy"
                maxDate={now()}
                onChange={handleChangeEnd}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
            <Stack direction="row" gap={4} mb={5}>
              <Grid>
                <Typography variant="h6">ยอดขายรวม</Typography>
                <Typography variant="h4">
                  {formatPrice(membershipTotal + ptTotal + productTotal)}
                </Typography>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid>
                <Typography variant="h6" color="text.secondary">
                  แพ็กเกจสมาชิก
                </Typography>
                <Typography variant="h4" color="text.secondary">
                  {formatPrice(membershipTotal)}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="h6" color="text.secondary">
                  แพ็กเกจเทรนเนอร์
                </Typography>
                <Typography variant="h4" color="text.secondary">
                  {formatPrice(ptTotal)}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="h6" color="text.secondary">
                  สินค้า
                </Typography>
                <Typography variant="h4" color="text.secondary">
                  {formatPrice(productTotal)}
                </Typography>
              </Grid>
            </Stack>
            <Typography variant="subtitle1" color="text.secondary">
              รายการขาย
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
              mb={3}
              mt={2}
            >
              <QueryTextField
                sx={{ width: 596 }}
                placeholder="ค้นหาด้วยชื่อสินค้าหรือชื่อสมาชิก"
              />
            </Stack>
            <ItemsTotal count={total} />
            <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
              <Table stickyHeader>
                <TableHead>
                  <SalesTableRowHeader />
                </TableHead>
                <DataTableBody loading={isLoading} data={data}>
                  {data.map((item) => (
                    <SalesTableRow
                      key={item.id}
                      data={item}
                      generatePDF={generatePDF}
                    />
                  ))}
                </DataTableBody>
              </Table>
            </TableContainer>
          </Stack>
        </CardContent>
      </Card>
      <Backdrop open={isPDFLoading} sx={{ zIndex: 10000 }}>
        <CircularLoading height="100vh" />
      </Backdrop>
      <CancelReceiptDialog {...cancelReceiptDialog} />
      <SearchParamsPagination total={total} />
    </Box>
  );
}
