import {
  Box,
  Breadcrumbs,
  Card,
  CircularProgress,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { useAtom } from "jotai";

import { getDocumentTitle, useRequireParams } from "@/utils";
import { getClass } from "@/services/class";
import { getPublished } from "@/features/class/utils";
import { selectedBranchAtom } from "@/atom/global";

import { ClassDetail } from "./ClassDetail";
import { ScheduleList } from "./ScheduleList";

const QUERY_KEY = "class";

export function ClassDetailPage() {
  const { id } = useRequireParams(["id"]);
  const [selectedBranch] = useAtom(selectedBranchAtom);

  const { data, isLoading } = useQuery([QUERY_KEY, selectedBranch], () =>
    getClass(id)
  );

  const isPublish = getPublished(data?.branches ?? [], selectedBranch);

  const title = data?.name ?? "";

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle(title)}</title>
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <MuiLink component={Link} color="text.secondary" to="/classes/calendar">
          คลาส
        </MuiLink>
        <MuiLink component={Link} color="text.secondary" to="/classes">
          จัดการคลาส
        </MuiLink>
        <Typography color="text.primary">รายละเอียดคลาส</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 2.5 }}>
        {!isLoading && data ? (
          <ClassDetail data={data} fetchKeys={[QUERY_KEY]} />
        ) : (
          <Box display="grid" sx={{ placeItems: "center" }} height={436}>
            <CircularProgress disableShrink />
          </Box>
        )}
      </Card>
      {data && <ScheduleList classId={id} isPublish={isPublish} />}
    </Box>
  );
}
