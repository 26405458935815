import {
  Box,
  Card,
  CardContent,
  Stack,
  TableContainer,
  Table,
  TableHead,
  CircularProgress,
  ButtonBase,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useAtom } from "jotai";
import { QueryTextField } from "@/components/QueryTextField";
import { DataTableBody } from "@/components/DataTableBody";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import {
  getMemberStatus,
  mapOptional,
  pickListTableParams,
  useRequireParams,
} from "@/utils";
import { getMembersBySale } from "@/services/member";

import { MemberTableRow, MemberTableRowHeader } from "./MemberTableRow";
import { MemberStatus } from "@/models";
import { formatMemberStatus, formatMemberStatusColor } from "@/formatter";
import { selectedBranchAtom } from "@/atom/global";
import { useMemo } from "react";

const QUERY_KEY = "saleMembers";

const list = [
  null,
  MemberStatus.Active,
  MemberStatus.Expired,
  MemberStatus.Ban,
];

function useMemberStats() {
  const { saleId: id } = useRequireParams(["saleId"]);

  const { data } = useQuery(["GET_ALL_MEMBERS_BY_SALE"], () =>
    getMembersBySale(id, "limit=1000")
  );

  return useMemo(
    () =>
      mapOptional(data, ({ total, data }) => ({
        total: total,
        ...data
          .map((data) => getMemberStatus(data))
          .reduce(
            (memo, status) => {
              memo[status]++;

              return memo;
            },
            {
              [MemberStatus.Active]: 0,
              [MemberStatus.Expired]: 0,
              [MemberStatus.Ban]: 0,
            } as Record<MemberStatus, number>
          ),
      })),
    [data]
  );
}

export function MemberListPage() {
  const { saleId: id } = useRequireParams(["saleId"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const query = pickListTableParams(searchParams);
  const [selectedBranch] = useAtom(selectedBranchAtom);
  const { data: raw, isLoading } = useQuery(
    [QUERY_KEY, query, selectedBranch],
    () => getMembersBySale(id, query)
  );

  const status = searchParams.get("status") as MemberStatus | undefined;

  const onClickStatus: JSX.IntrinsicElements["button"]["onClick"] = (event) => {
    const selected = event.currentTarget.dataset.status;
    if (selected) {
      searchParams.set("status", selected);
    } else {
      searchParams.delete("status");
    }

    setSearchParams(searchParams);
  };

  const statusFilters = mapOptional(useMemberStats(), (stats) =>
    list.map((item) => ({
      label: mapOptional(item, formatMemberStatus) ?? "ทั้งหมด",
      bgcolor:
        item === status
          ? mapOptional(item, formatMemberStatusColor) ?? "primary.main"
          : "grey.100",
      color: item === status ? "white" : "black",
      quantity: item ? stats[item] : stats.total,
      value: item,
    }))
  );

  const data = raw?.data ?? [];
  const total = raw?.total;

  return (
    <Stack gap={3}>
      <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={2.5}>
        {!statusFilters ? (
          <Box
            display="grid"
            height={114}
            sx={{ gridColumn: "1/-1", placeItems: "center" }}
          >
            <CircularProgress disableShrink />
          </Box>
        ) : (
          statusFilters.map(({ value, label, bgcolor, color, quantity }) => (
            <Stack
              component={ButtonBase}
              data-status={value}
              onClick={onClickStatus}
              key={label}
              sx={{
                bgcolor,
                color,
                height: 114,
                p: 2.5,
                borderRadius: 1,
                alignItems: "flex-start",
              }}
              gap={1.25}
            >
              <Typography variant="subtitle2">{label}</Typography>
              <Typography variant="h4">{quantity}</Typography>
            </Stack>
          ))
        )}
      </Box>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
            mb={5}
          >
            <QueryTextField
              sx={{ width: 400 }}
              placeholder="ค้นหาด้วยหมายเลขสมาชิกหรือชื่อ"
            />
          </Stack>
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <MemberTableRowHeader />
              </TableHead>
              <DataTableBody loading={isLoading} data={data}>
                {data.map((item) => (
                  <MemberTableRow key={item.id} data={item} />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
    </Stack>
  );
}
