import { useSnackbar } from "notistack";
import { Duration } from "luxon";
import { useMutation } from "react-query";

import { formatPurchaseType } from "@/formatter";
import { configs } from "@/configs";
import { createProductPT } from "@/services/product";

import { useProductPTEditorForm } from "./ProductPTEditor";
import { ProductPTEditorDialog } from "./ProductPTEditorDialog";

import { PurchaseType, QuotaUnit } from "@/models";

export function AddProductPTDialog({
  open: isOpen,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useProductPTEditorForm();

  const { mutate: add, isLoading } = useMutation(createProductPT, {
    onSuccess: () => {
      enqueueSnackbar(
        `เพิ่มแพ็กเกจเทรนเนอร์สำเร็จ`,
        { variant: "success" }
      );
      close();
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar(configs.unknownErrorMessage, { variant: "error" });
      close();
    },
  });

  function close() {
    reset();
    onClose();
  }

  const onSubmit = handleSubmit((form) => {
    const data = {
      name: form.name,
      price: form.price,
      description: form.description,
      durationIso: Duration.fromObject({
        [form.durationUnit]: form.duration,
      }).toISO(),
      quota: form.quota,
      quotaUnit: form.quotaUnit as QuotaUnit,
      type: form.type,
      branches: form.branches.map((branch) => branch.id),
    };
    add(data);
  });
  const title = `เพิ่มแพ็กเกจเทรนเนอร์`;

  return (
    <ProductPTEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
