import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import { DataTableBody } from "@/components/DataTableBody";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { getDocumentTitle } from "@/utils";
import { getFacilities } from "@/services/facility";

import { FacilityTableRow, FacilityTableRowHeader } from "./FacilityTableRow";
import { RemoveFacilityDialog } from "../../components/RemoveFacilityDialog";
import { AddFacilityDialog } from "./AddFacilityDialog";
import { EditFacilityDialog } from "../../components/EditFacilityDialog";

const QUERY_KEY = "facilities";

export function FacilityListPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: raw, isLoading } = useQuery([QUERY_KEY], () => getFacilities());

  const data = raw?.data ?? [];
  const total = raw?.total;

  function add() {
    searchParams.set("dialog", "add");
    setSearchParams(searchParams);
  }

  function onCloseDialog() {
    searchParams.delete("dialog");
    searchParams.delete("id");
    setSearchParams(searchParams, { replace: true });
  }

  const dialog = searchParams.get("dialog");
  const id = searchParams.get("id") ?? "";

  const addDialog = {
    open: dialog === "add",
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  const editDialog = {
    open: dialog === "edit",
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
    data: data.find((item) => item.id.toString() === id),
  };

  const removeDialog = {
    open: dialog === "remove",
    id,
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  const title = "สิ่งอำนวยความสะดวก";

  return (
    <Stack>
      <Helmet>
        <title>{getDocumentTitle(title)}</title>
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <Typography>{title}</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            mb={3}
          >
            <Button variant="contained" size="large" onClick={add}>
              เพิ่มสิ่งอำนวยความสะดวก
            </Button>
          </Stack>
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <FacilityTableRowHeader />
              </TableHead>
              <DataTableBody loading={isLoading} data={data}>
                {data.map((item) => (
                  <FacilityTableRow key={item.id} data={item} />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
      <AddFacilityDialog {...addDialog} />
      <EditFacilityDialog {...editDialog} />
      <RemoveFacilityDialog {...removeDialog} />
    </Stack>
  );
}
